<template>
  <div>
    <div class="notification" v-if="status">
      <Header />
      <div class="notification-block">
        <h1>{{$t('notifications')}}</h1>
        <ul class="notify-list">
          <li v-for="(list,index) in allData " :key="index" class="video-notification">
            <span class="notif-img">
              <img v-if="list.user_profile" :src="list.user_profile" alt />
              <img v-else src="../assets/images/dummy.png" alt />
            </span>
            <div class="notif-content">
              <div class="text-part">
                <h2>{{list.msg}}</h2>
                <span>{{ChangeFormat(list.time)}}</span>
              </div>
              <span  class="upload-video" @click="playVideo(list)">
                <img
                  v-if="list.celeb_profile && list.type === 'Video'"
                  src="../assets/images/youtube-play.svg"
                  alt
                  class="video-logo"
                />
                <img v-if="list.type === 'Video'" :src="list.celeb_profile" alt />
                <img v-if="list.type === 'Image'" :src="list.media" alt />
              </span>
            </div>
          </li>
          <Toaster :dataValue="toasterObj" />
          <infinite-loading spinner="waveDots" @infinite="getNotification">
            <div slot="no-more">......</div>
            <div class="no-data-status" slot="no-results">
              <span class="no-dt" v-if="allData.length === 0">{{$t('noNotifications')}}</span>
            </div>
          </infinite-loading>
        </ul>
      </div>
      <Footer />
    </div>
    <div class="video-listings" v-if="!status && CommentsStatus ">
      <div class="videos-listing-card">
        <VideoListingCard
          :requestData="request_id"
          @closeVideoModel="VideoPopup"
          @commentsData="commentsVideoData"
        />
      </div>
    </div>
    <VideoComments
      v-if="!CommentsStatus"
      :popupData="commentsObjData"
      @closeCommentsPopup="changeVideoStatus"
    />
  </div>
</template>

<script>
import '../assets/scss/video-listings.scss'
import Toaster from '@/components/Common/Toaster'
import '../assets/scss/notification.scss'
import Header from '@/components/Common/Header'
import Footer from '@/components/Common/Footer'
import VideoPlayer from '@/components/Common/VideoPlayer'
import { mapMutations, mapGetters } from 'vuex'
import VideoListingCard from '@/components/VideoListingCard'
import VideoComments from '@/components/VideoComments'
export default {
  name: 'Notifications',
  components: {
    Header,
    Footer,
    VideoPlayer,
    Toaster,
    VideoListingCard,
    VideoComments
  },
  data () {
    return {
      startIndex: 0,
      allData: [],
      src: '',
      poster: '',
      status: true,
      toasterObj: {},
      request_id: '0',
      CommentsStatus: true,
      commentsObjData: {}
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['notificationIndex', 'notificationData'])
  },
  mounted () {
    if (this.notificationIndex && this.notificationData.length) {
      this.allData = this.this.notificationData
      this.startIndex = this.notificationIndex
    }
  },
  methods: {
    ...mapMutations(['saveNotificationData']),
    commentsVideoData (obj) {
      this.CommentsStatus = false
      this.commentsObjData = obj
    },
    changeVideoStatus (value) {
      // this.videoListingData[value.index].comments = value.count
      this.CommentsStatus = true
    },
    ChangeFormat (value) {
      const date = new Date(value)
      return date.toDateString()
    },
    playVideo (value) {
      if (value.type === 'Video') {
        this.status = false
        this.request_id = value.request_id
        this.src = value.media
        this.poster = value.celeb_profile
      }
    },
    VideoPopup (value) {
      this.status = value
    },
    getNotification ($state) {
      this.$store
        .dispatch({
          type: 'getNotifications',
          startIndex: this.startIndex
        })
        .then(data => {
          if (data.data.status !== 'no user') {
            if (data.data.length) {
              this.allData.push(...data.data)
              this.startIndex = this.startIndex + 10
              this.saveNotificationData({
                notificationData: this.allData,
                notificationIndex: this.startIndex
              })
              $state.loaded()
            } else {
              $state.complete()
            }
          } else {
            this.toasterObj = {
              status: true,
              msg: this.$t('userNotFound'),
              color: '#000000'
            }
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    }
  }
}
</script>
