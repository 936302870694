<template>
  <div>
    <div class="celebrities-details" v-if="CommentsStatus">
      <header class="request-header hid">
        <span class="goback-btn">
          <img @click="back()" src="../../assets/images/goback-arrow.svg" alt />
        </span>
        <div class="head-profile">
          <span class="user-pf-img">
            <img :src="celebrityInfoData.profile_pic" alt />
          </span>
          <span class="user-title">{{celebrityInfoData.name}}</span>
        </div>
      </header>
      <header class="celebrity-info-header">
        <span class="goback-btn">
          <img @click="back()" src="../../assets/images/goback-arrow.svg" alt />
        </span>
      </header>
      <CelebrityInfo :dataValue="celebrityInfoData" />
      <ul class="tab-head">
        <li class="cstm_rado" :class="{'active' : !isActive}">
          <input
            type="radio"
            id="test1"
            name="radio-group"
            value="videoList"
            v-model="status"
            @change="changeStatus('videoList')"
          />
          <label for="test1">{{$t('videos')}}</label>
        </li>
        <li class="cstm_rado" :class="{'active' : isActive}">
          <input
            type="radio"
            id="test2"
            name="radio-group"
            value="photo"
            v-model="status"
            @change="changeStatus('photo')"
          />
          <label for="test2">{{$t('photo')}}</label>
        </li>
      </ul>

      <VideoListingCard
        v-if="status == 'videoList'"
        :dataValue="videoListingData"
        :celebrity="celebrityData"
        @commentsData="commentsVideoData"
      />

      <infinite-loading
        v-if="status === 'videoList'"
        spinner="waveDots"
        @infinite="getCelebrityVideo"
      >
        <div slot="no-more">......</div>
        <div class="no-data-status" slot="no-results">
          <span class="no-dt">{{$t('noVideo')}}</span>
        </div>
      </infinite-loading>
      <CelebrityPhoto v-if="status == 'photo'" />

      <Toaster :dataValue="toasterObj" />
      <footer class="footer-btn" v-if="celebrityInfoData.active" >
        <span @click="requestVideo(celebId)">{{$t('requestVideo',{priceTxt:celebrityInfoData.fee})}}</span>
      </footer>
      <div class="loader" v-if="loading">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>

    <VideoComments
      v-if="!CommentsStatus"
      :popupData="commentsObjData"
      @closeCommentsPopup="changeVideoStatus"
    />
  </div>
</template>

<script>
import '../../assets/scss/celebrities-details.scss'
import CelebrityInfo from './CelebrityInfo'
import VideoListingCard from '../VideoListingCard'
import CelebrityPhoto from './CelebrityPhoto'
import VideoCreateInfo from '../CreateVideo/VideoCreateInfo'
import Toaster from '../Common/Toaster'
import { mapGetters, mapMutations } from 'vuex'
import VideoComments from '../VideoComments'
export default {
  name: 'CelebrityDetail',
  components: {
    CelebrityInfo,
    VideoListingCard,
    CelebrityPhoto,
    VideoCreateInfo,
    Toaster,
    VideoComments
  },
  data () {
    return {
      celebrityInfoData: {},
      status: 'videoList',
      isActive: false,
      videoListingData: [],
      celebrityData: [],
      videoStartIndex: 0,
      celebId: this.$route.params.id,
      loading: false,
      requestVideoStatus: true,
      instagramData: [],
      photoStartIndex: 0,
      toasterObj: {},
      commentsObjData: {},
      CommentsStatus: true
    }
  },
  watch: {},
  computed: {
    ...mapGetters([
      'celebrityDetail',
      'celebrityVideosIndex',
      'celebrityVideos'
    ])
  },
  mounted () {
    if (
      this.celebrityDetail &&
      this.celebrityDetail.id === Number(this.$route.params.id)
    ) {
      this.celebrityInfoData = this.celebrityDetail
      this.videoListingData = this.celebrityVideos
      this.videoStartIndex = this.celebrityVideosIndex
    } else {
      // this.getCelebrityVideo();
      this.getCelebrityDetail()
    }

    let header = document.getElementsByClassName('celebrities-details')[0]
      .children[0]
    window.onscroll = function () {
      scrollFunction()
    }

    function scrollFunction () {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        header.classList.add('sticky')
      } else {
        header.classList.remove('sticky')
      }
    }

    // let b=document.getElementsByClassName('celebrities-details')[0].children[0]
    // b.classList.add("sticky")
  },
  methods: {
    ...mapMutations([
      'saveCelebrityDetail',
      'saveCelebrityVideos',
      'saveUserProfileData',
      'saveCelebrityPhotos'
    ]),
    Onscroll () {
      let header = document.getElementsByClassName('celebrities-details')[0]
        .children[0]
      window.onscroll = function () {
        scrollFunction()
      }

      function scrollFunction () {
        if (
          document.body.scrollTop > 150 ||
          document.documentElement.scrollTop > 150
        ) {
          header.classList.add('sticky')
        } else {
          header.classList.remove('sticky')
        }
      }
    },
    commentsVideoData (obj) {
      this.CommentsStatus = false
      this.commentsObjData = obj
    },
    changeVideoStatus (value) {
      // this.videoListingData[value.index].comments = value.count
      this.CommentsStatus = true

      setTimeout(() => {
        let header = document.getElementsByClassName('celebrities-details')[0]
          .children[0]
        header.classList.add('sticky')
        window.onscroll = function () {
          scrollFunction()
        }

        function scrollFunction () {
          if (
            document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
          ) {
            header.classList.add('sticky')
          } else {
            header.classList.remove('sticky')
          }
        }
      }, 10)
    },
    requestPopup (value) {
      this.requestVideoStatus = value
    },
    changeStatus (status) {
      // this.$cookies.set("status", order_status);
      this.status = status
      if (status === 'videoList') {
        this.isActive = !this.isActive
      } else {
        this.isActive = !this.isActive
      }
    },
    back () {
      this.$router.go(-1)
    },
    requestVideo (id) {
      this.$router.push({ name: 'VideoCreateInfo', params: { id: id } })
    },
    getCelebrityVideo ($state) {
      if (this.videoStartIndex === 0) {
        this.loading = true
      } else {
        this.loading = false
      }
      this.$store
        .dispatch({
          type: 'getCelebrityVideo',
          startIndex: this.videoStartIndex,
          id: this.$route.params.id
        })
        .then(data => {
          if (data.data.length) {
            this.videoListingData.push(...data.data)
            this.videoStartIndex = this.videoStartIndex + 10
            this.saveCelebrityVideos({
              celebrityVideosIndex: this.videoStartIndex,
              celebrityVideos: this.videoListingData
            })
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    },
    getCelebrityDetail () {
      // this.loading = true;
      this.$store
        .dispatch({
          type: 'getCelebrityDetail',
          id: this.$route.params.id
        })
        .then(data => {
          this.saveUserProfileData({ userProfileData: data.data.user })
          this.celebrityInfoData = data.data.celebrity
          this.saveCelebrityDetail({ celebrityDetail: data.data.celebrity })
          this.saveCelebrityPhotos({
            celebrityPhotos: [],
            celebrityPhotosIndex: 0
          })
          // this.loading = false;
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    }
    // getPhoto ($state) {
    //   if (this.photoStartIndex === 0) {
    //     this.loading = true
    //   } else {
    //     this.loading = false
    //   }
    //   this.$store
    //     .dispatch({
    //       type: 'getCelebrityPhoto',
    //       startIndex: this.photoStartIndex,
    //       id: this.$route.params.id
    //     })
    //     .then(data => {
    //       console.log('d hb vh', data)
    //       if (data.data.length) {
    //         this.instagramData.push(...data.data)
    //         this.photoStartIndex = this.photoStartIndex + 20
    //         this.saveCelebrityPhotos({
    //           celebrityPhotos: this.instagramData,
    //           celebrityPhotosIndex: this.photoStartIndex
    //         })
    //         $state && $state.loaded()
    //       } else {
    //         $state && $state.complete()
    //       }
    //       this.loading = false
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.loading = false
    //       this.toasterObj = {
    //         status: true,
    //         msg: this.$t('apiError'),
    //         color: '#000000'
    //       }
    //     })
    // }
  }
}
</script>
