<template>
  <div>
    <div class="create-video-listings" v-if="popup">
      <Header />
      <ul class="tab-head">
        <li class="cstm_rado" :class="{'active dot' : !isActive}">
          <input
            type="radio"
            id="test1"
            name="radio-group"
            value="order"
            v-model="status"
            @change="changeStatus('order')"
          />
          <label for="test1">{{$t('orders')}}</label>
        </li>
        <li class="cstm_rado" :class="{'active dot' : isActive}">
          <input
            type="radio"
            id="test2"
            name="radio-group"
            value="video"
            v-model="status"
            @change="changeStatus('video')"
          />
          <label for="test2">{{$t('videos')}}</label>
        </li>
      </ul>
       <transition name="custom-classes-transition" enter-active-class="animated bounceInRight">
       <Video v-if="status === 'video'" />
       </transition>
      <transition name="custom-classes-transition" enter-active-class="animated bounceInRight">
        <Order v-if="status === 'order'" @popup="popupStatus" />
      </transition>

      <Footer />
    </div>
    <!-- order Deatail popup -->
    <transition name="custom-classes-transition" enter-active-class="animated bounceInRight">
      <VideoDetailInfo v-if="!popup" :dataValue="popupData" @popupStatus="close" :user="userData" />
    </transition>
  </div>
</template>

<script>
import '../assets/scss/create-video-listing.scss'
import Header from '@/components/Common/Header'
import Footer from '@/components/Common/Footer'
import Order from '@/components/Profile/Order'
import Video from '@/components/Profile/Video'
import VideoDetailInfo from '@/components/Common/VideoDetailInfo'
export default {
  name: 'Profile',
  components: {
    Header,
    Footer,
    Order,
    Video,
    VideoDetailInfo
  },
  data () {
    return {
      status: 'order',
      isActive: false,
      popup: true,
      popupData: {},
      userData: {}
    }
  },
  watch: {},
  methods: {
    popupStatus (value) {
      this.popup = false
      this.popupData = value.data
      this.userData = value.user
    },
    changeStatus (status) {
      // this.$cookies.set("status", order_status);
      this.status = status
      if (status === 'order') {
        this.isActive = !this.isActive
      } else {
        this.isActive = !this.isActive
      }
    },
    close (value) {
      this.popup = value
    }
  }
}
</script>
