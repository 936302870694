<template>
  <div>
    <ul class="order-listing">
      <li v-for="(list,index) in orderList" :key="index" v-if="list.status !== ''" @click="openPopup(list,userData)">
        <span class="video-logo">
          <img src="../../assets/images/order-video.svg" alt />
        </span>
        <div class="order-content" :class="ChangeStatus(list.status)">
          <h2 v-if="list.third_party_name">{{$t('videoFor',{name:list.third_party_name})}}</h2>
          <h2 v-else>{{$t('videoFor',{name:userData.name})}}</h2>
          
          <p>{{list.request_content}}</p>
          <img
            src="../../assets/images/right-arow.svg"
            alt
            class="right-arow"
          />
          <span class="tag">{{ChangeColor(list.status)}}</span>
        </div>
      </li>
    </ul>
    <Toaster :dataValue="toasterObj" />
    <infinite-loading spinner="waveDots" @infinite="getOrderList">
      <div slot="no-more">......</div>
      <div class="no-data-status" slot="no-results" >
        <span class="no-dt"  v-if="orderList.length === 0">{{$t('noOrdersYet')}}</span>
        <span class="comman-btn"  v-if="orderList.length === 0" @click="redirect">{{$t('createVideoButton')}}</span>
      </div>
    </infinite-loading>
    <div class="loader" v-if="loading">
      <img src="../../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import Toaster from '../Common/Toaster'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Order',
  components: {
    Toaster
  },
  data () {
    return {
      orderList: [],
      popupData: {},
      userData: {},
      orderIndex: 0,
      loading: false,
      toasterObj: {}
    }
  },
  watch: {},
  computed: {
    ...mapGetters([
      'profileOrdersList',
      'profileOrdersListIndex',
      'userProfileData'
    ])
  },
  mounted () {
    // if (this.userProfileData && this.profileOrdersList.length > 0) {
    //   this.userData = this.userProfileData
    //   this.orderList = this.profileOrdersList
    //   this.orderIndex = this.profileOrdersListIndex
    // }
  },
  methods: {
    ...mapMutations(['saveProfileOrdersList']),
    openPopup (data, user) {
      let obj = { user: user, data: data }
      this.$emit('popup', obj)
    },
    redirect () {
      this.$cookies.set('status', 'celebrity')
      this.$router.push({ name: 'home' })
    },
    getOrderList ($state) {
      // this.loading = true
      this.$store
        .dispatch({
          type: 'getOrderlist',
          startIndex: this.orderIndex
        })
        .then(data => {
          if (data.data.orders.length > 0) {
            this.userData = data.data.user
            this.orderList.push(...data.data.orders)
            this.orderIndex = this.orderIndex + 10
            this.saveProfileOrdersList({
              profileOrdersList: this.orderList,
              profileOrdersListIndex: this.orderIndex,
              userProfileData: this.userData
            })
            $state && $state.loaded()
            this.loading = false
          } else {
            $state && $state.complete()
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
          this.loading = false
        })
    },
    ChangeStatus (status) {
      if (status === 'Reject') {
        return 'reject'
      } else if (status === 'Pending') {
        return 'pending'
      } else if (status === 'Accept') {
        return 'approved'
      } else if (status === 'Complete') {
        return 'video'
      } else if (status === 'Incomplete') {
        return 'incomplete'
      }
    },
    ChangeColor (status) {
      if (status === 'Reject') {
        return this.$t('reject')
      } else if (status === 'Pending') {
        return this.$t('pending')
      } else if (status === 'Accept') {
        return this.$t('approved')
      } else if (status === 'Complete') {
        return this.$t('video')
      } else if (status === 'Incomplete') {
        return this.$t('incomplete')
      }
    }
  }
}
</script>
