<template>
  <div>
    <div class="videos-listing-card">
      <div class="video-card" v-for="(list,index) in videoList" :key="index">
        <div class="card-inner">
          <div class="feed-card-head">
            <span class="card-profile">
              <img :src="list.celeb_pic" alt />
            </span>
            <div class="card-head-content">
              <p v-html="$t('createdVideoFor',{name:userData.name,celebrity:list.celeb_name})"></p>
              <span>{{$t('posted',{post:ChangeFormat(list.request_time)})}}</span>
            </div>
            <img src="../../assets/images/new.png" alt class="new-tag" v-if="list.viewed == 'new'" />
          </div>
          <div class="feed-video">
            <img
              @click="playVideo(index)"
              src="../../assets/images/youtube-play.svg"
              alt
              class="video-icon"
              v-if="playingIndex != index"
            />
            <ImageItem
              class="article-item__image"
              v-if="list.thumbnail && playingIndex !== index"
              :source="list.thumbnail"
              @click="playVideo(index)"
            />
            <!-- <img :src="list.thumbnail" alt v-if="playingIndex !== index" @click="playVideo(index)" /> -->
            <VideoPlayer
              :poster="list.thumbnail"
              :video_link="list.video"
              :id="list.media_id"
              v-if="playingIndex === index"
            />
          </div>
          <div class="feed-card-footer">
            <div class="reply-block">
              <div class="social-icon">
                <span class="icon-img">
                  <img src="../../assets/images/activity.svg" alt />
                </span>
                <span @click="downlaod(list)">{{$t('download')}}</span>
              </div>
              <div class="social-icon">
                <span class="icon-img">
                  <img src="../../assets/images/comment.svg" alt />
                </span>
                <span @click="shareCount(list)">{{$t('share')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading spinner="waveDots" @infinite="getAllVideo">
      <div slot="no-more">......</div>
      <div class="no-data-status" slot="no-results">
        <span class="no-dt" v-if="videoList.length === 0">{{$t('noOrdersYet')}}</span>
        <span
          class="comman-btn"
          v-if="videoList.length === 0"
          @click="redirect"
        >{{$t('createVideoButton')}}</span>
      </div>
    </infinite-loading>
    <Toaster :dataValue="toasterObj" />
    <div class="loader" v-if="loading">
      <img src="../../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import ImageItem from '../Common/ImageItem'
import VideoPlayer from '../Common/VideoPlayer'
import Toaster from '../Common/Toaster'
import { mapMutations, mapGetters } from 'vuex'
import { checkStatus, parseJSON } from '../../utils/responseHandler'
import axios from 'axios'
import VueCookie from 'vue-cookies'
export default {
  name: 'Video',
  components: {
    VideoPlayer,
    Toaster,
    ImageItem
  },
  data () {
    return {
      videoList: [],
      videoStartIndex: 0,
      userData: {},
      playingIndex: null,
      loading: false,
      toasterObj: {}
    }
  },
  watch: {},
  computed: {
    ...mapGetters([
      'profileVideosList',
      'profileVideosListIndex',
      'userProfileData'
    ])
  },
  mounted () {
    if (this.userProfileData && this.profileVideosList.length > 0) {
      this.videoList = this.profileVideosList
      this.videoStartIndex = this.profileVideosListIndex
      this.userData = this.userProfileData
    } else {
      // this.getAllVideo();
    }
  },
  methods: {
    ...mapMutations(['saveProfileVideosList', 'saveUserProfileData']),
    ChangeFormat (value) {
      const date = new Date(value)
      return date.toDateString()
    },
    getAllVideo ($state) {
      // this.loading = true
      this.$store
        .dispatch({
          type: 'getVideoOrder',
          startIndex: this.videoStartIndex,
          payload: 0
        })
        .then(data => {
          if (data.data.orders.length > 0) {
            this.userData = data.data.user
            this.saveUserProfileData({ userProfileData: this.userData })
            this.videoList.push(...data.data.orders)
            this.videoStartIndex = this.videoStartIndex + 10
            this.saveProfileVideosList({
              profileVideosList: this.videoList,
              profileVideosListIndex: this.videoStartIndex
            })
            this.loading = false
            $state && $state.loaded()
          } else {
            $state && $state.complete()
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
          this.loading = false
        })
    },
    playVideo (index) {
      // if (this.playingIndex !== null) {
      //   let data = document.getElementById(this.playingIndex);
      //   data.pause();
      // }

      if (this.playingIndex === index) {
        // let inner = document.getElementById(this.playingIndex);
        // inner.pause();
        this.playingIndex = null
      } else {
        this.playingIndex = index
        // let outer = document.getElementById(this.playingIndex);
        // outer.play();
        // this.playingIndex = index;
      }
    },
    redirect () {
      this.$cookies.set('status', 'celebrity')
      this.$router.push({ name: 'home' })
    },
    isDigit (text) {
      return /^\d+$/.test(Number(text))
    },
    replcePhoneWithAsterik (no) {
      if (this.isDigit(no)) {
        return no.replace(/\d(?=\d{3})/g, '*')
      }
      return no
    },
    shareCount (list) {
      this.loading = true
      this.$store
        .dispatch({
          type: 'Share',
          payload: {
            media_id: list.media_id,
            request_id: list.request_id
          }
        })
        .then(data => {
          if (data.data.sucess) {
            this.share(list)
          }
        })
        .catch(err => {
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
          this.loading = false
        })
    },
    async share (list) {
      // const header = {
      //   'Content-Type': 'application/json'
      // }
      // let deepLink = await axios({
      //   method: 'post',
      //   url: `https://api.branch.io/v1/url`,
      //   headers: header,
      //   data: {
      //     branch_key: 'key_live_cfVgkkPBIPJSmXJaiBjdvmcgtxmtPPbj',
      //     channel: 'star91',
      //     feature: 'celebvideo',
      //     campaign: '',
      //     stage: 'prod',
      //     tags: ['star91', 'celebrity'],
      //     data: {
      //       id: 172,
      //       iconImageURL:
      //         'https://d3ojr524tgla9b.cloudfront.net/90c394b7-0687-489a-86e1-6af393e5828d_original.png',
      //       name: 'Star91',
      //       applicationURL: window.location.href,
      //       miniapp: true
      //     }
      //   }
      // })
      //   .then(checkStatus)
      //   .then(parseJSON)
      //   .catch(error => {
      //     console.log('.....>error>.....', error)
      //   })
      this.loading = false
      try {
        const obj = {
          message: 'https://money91.app.link'
        }
        console.log(obj)
        JSBridgePlugin.share(JSON.stringify(obj))
      } catch (err) {
        console.log(err)
        let text = 'https://money91.app.link'
        window.open('whatsapp://send?text=' + text)
      }
    },
    async downlaod (list) {
      this.loading = true
      // const header = {
      //   'Content-Type': 'application/json'
      // }
      // let deepLink = await axios({
      //   method: 'post',
      //   url: `https://api.branch.io/v1/url`,
      //   headers: header,
      //   data: {
      //     branch_key: 'key_live_cfVgkkPBIPJSmXJaiBjdvmcgtxmtPPbj',
      //     channel: 'star91',
      //     feature: 'celebvideo',
      //     campaign: '',
      //     stage: 'prod',
      //     tags: ['star91', 'celebrity'],
      //     data: {
      //       id: 172,
      //       iconImageURL:
      //         'https://d3ojr524tgla9b.cloudfront.net/90c394b7-0687-489a-86e1-6af393e5828d_original.png',
      //       name: 'Star91',
      //       applicationURL: window.location.href,
      //       miniapp: true
      //     }
      //   }
      // })
      //   .then(checkStatus)
      //   .then(parseJSON)
      //   .catch(error => {
      //     console.log('.....>error>.....', error)
      //   })
      var obj = {
        imageUrl: list.thumbnail,
        message: `${list.celeb_name} ने ${this.replcePhoneWithAsterik(
          this.userData.name
        )} के लिए वीडियो मैसेज बनाया है मॉल91 पर \nhttps://money91.app.link \nडाउनलोड करें Mall91 ऐप \nMall91 - 30 लाख+ भारतवासियों की विश्वसनीय सुपर ऐप! घर बैठे बैठे ढेरों कमाएं और अपने कमाई का उपयोग करें दैनिक ज़रूरतों का सामान खरीदने में, रीचार्ज/बिल्स भुगतान में या किसी भी दूकान पर पेमेंट करने में! कमाना है बड़ा आसान - बस अपने दोस्तों को रेफेर करके नेटवर्क बनाकर, या मज़ेदार गेम्स टूर्नामेंट्स खेल कर, या सिर्फ हमारे प्रोडक्ट्स व्हाट्सऐप पर शेयर करके! नेटवर्क की हर खरीद पर आप पाएंगे अलग से कमीशन! यही नहीं, हमारी कई और मिनी-ऐप्स उपयोग करें और बनाएं अपने दैनिक जीवन को और भी आसान।`
      }
      if (VueCookie.get('version-code') > 54) {
        this.loading = false
        try {
          JSBridgePlugin.downloadVideoAndShare(
            list.video,
            `${list.celeb_name} ने ${this.replcePhoneWithAsterik(
              this.userData.name
            )} के लिए वीडियो मैसेज बनाया है मॉल91 पर  \nडाउनलोड करें Mall91 ऐप \nMall91 - 30 लाख+ भारतवासियों की विश्वसनीय सुपर ऐप! घर बैठे बैठे ढेरों कमाएं और अपने कमाई का उपयोग करें दैनिक ज़रूरतों का सामान खरीदने में, रीचार्ज/बिल्स भुगतान में या किसी भी दूकान पर पेमेंट करने में! कमाना है बड़ा आसान - बस अपने दोस्तों को रेफेर करके नेटवर्क बनाकर, या मज़ेदार गेम्स टूर्नामेंट्स खेल कर, या सिर्फ हमारे प्रोडक्ट्स व्हाट्सऐप पर शेयर करके! नेटवर्क की हर खरीद पर आप पाएंगे अलग से कमीशन! यही नहीं, हमारी कई और मिनी-ऐप्स उपयोग करें और बनाएं अपने दैनिक जीवन को और भी आसान।\n http://money91.app.link/twB3XD1OWQ`
          )
        } catch (err) {
          console.log(err)
        }
        window.open(list.video)
        // try {
        //   JSBridgePlugin.downloadVideoAndShare(
        //     list.video,
        //     `${list.celeb_name} ने ${this.replcePhoneWithAsterik(
        //       this.userData.name
        //     )} के लिए वीडियो मैसेज बनाया है मॉल91 पर  \nडाउनलोड करें Mall91 ऐप \nMall91 - 30 लाख+ भारतवासियों की विश्वसनीय सुपर ऐप! घर बैठे बैठे ढेरों कमाएं और अपने कमाई का उपयोग करें दैनिक ज़रूरतों का सामान खरीदने में, रीचार्ज/बिल्स भुगतान में या किसी भी दूकान पर पेमेंट करने में! कमाना है बड़ा आसान - बस अपने दोस्तों को रेफेर करके नेटवर्क बनाकर, या मज़ेदार गेम्स टूर्नामेंट्स खेल कर, या सिर्फ हमारे प्रोडक्ट्स व्हाट्सऐप पर शेयर करके! नेटवर्क की हर खरीद पर आप पाएंगे अलग से कमीशन! यही नहीं, हमारी कई और मिनी-ऐप्स उपयोग करें और बनाएं अपने दैनिक जीवन को और भी आसान।\n http://money91.app.link/twB3XD1OWQ`
        //   );
        // } catch (err) {
        //   console.log(err);
        // }
      } else {
        this.loading = false
        try {
          JSBridgePlugin.share(JSON.stringify(obj))
        } catch (err) {
          console.log(err)
          let text = `https://money91.app.link`
          window.open('whatsapp://send?text=' + text)
        }
      }
    }
  }
}
</script>
