<template>
  <div class="request-block">
    <header class="request-header">
      <span class="goback-btn">
        <img @click="popup()" src="../../assets/images/goback-arrow.svg" alt />
      </span>
      <div class="head-profile">
        <span class="user-pf-img">
          <img :src="popupData.profile_pic" alt />
        </span>
        <span class="user-title">{{popupData.name}}</span>
      </div>
        <span v-if="popupData.status" class="header-status" :class="ChangeStatus(popupData.status)">
          <span class="tag">{{ChangeColor(popupData.status)}}</span>
        </span>
    </header>

    <div class="request-info">
      <div class="request-description">
        <h4 v-if="popupData.third_party_name">{{$t('videoFor',{name:popupData.third_party_name})}}</h4>
        <h4 v-if="commentData.name">{{$t('videoFor',{name:commentData.name})}}</h4>
        <p v-if="popupData.request_content">{{popupData.request_content}}</p>
        <p v-else>{{commentData.message}}</p>
      </div>
      <div class="price-details-card">
        <h4>{{$t('priceDetails')}}</h4>
        <ul v-if="!isVipUser">
          <li>
            <span class="title">{{$t('costPrice')}}</span>
            <span
              class="price"
              v-if="popupData.wallet || popupData.discount_amount || popupData.gateway_amount  "
            >₹ {{celebsCalulate(popupData.discount_amount,popupData.wallet,popupData.gateway_amount )}}</span>
            <span class="price" v-else>₹ {{popupData.fee}}</span>
          </li>
          <li>
            <span class="title">{{$t('discount')}}</span>
            <span class="price" v-if="popupData.discount_amount">₹ {{popupData.discount_amount}}</span>
            <span
              class="price"
              v-if="discountAmount && booked === 'VideoCreateInfo'"
            >₹ {{calculateDisAmount(discountAmount,popupData.fee)}}</span>
            <span class="price" v-if="!popupData.discount_amount && discountAmount === 0 && booked === 'VideoCreateInfo'" >₹ 0.00</span>
            <span class="price" v-if="popupData.discount_amount === null" >₹ 0.00</span>
          </li>
          <li>
            <span class="title">{{$t('walletUsed')}}</span>
            <span
              class="price"
              v-if="userData.wallet&& !popupData.wallet"
            >₹ {{calculateDisCount(popupData.fee,discountAmount,userData.wallet)}}</span>
            <span class="price" v-if="popupData.wallet">₹ {{popupData.wallet}}</span>
            <span class="price" v-if="!popupData.wallet && !userData.wallet">₹ 0.0</span>
          </li>
          <li v-if="popupData.gateway  && popupData.gateway_amount !==null">
            <span class="title">{{popupData.gateway}}</span>
            <span class="price">₹ {{popupData.gateway_amount}}</span>
          </li>
          <li>
            <span class="title">{{$t('totalAmount')}}</span>
            <span
              class="price"
              v-if="popupData.wallet || popupData.gateway_amount || popupData.discount_amount"
            >₹ {{calculateTotal(popupData.wallet,popupData.discount_amount,popupData.gateway_amount)}}</span>
            <span
              class="price"
              v-else
            >₹ {{calculateRequestTotal(popupData.fee,discountAmount,userData.wallet)}}</span>
          </li>

        </ul>
        <ul v-else>
          <li>
            <span class="title">{{$t('costPrice')}}</span>
            <span class="price" >₹ {{popupData.fee || 0}}</span>
          </li>
          <li>
            <span class="title">VIP Discount</span>
            <span class="price" >₹ {{popupData.fee?'-':''}} {{popupData.fee || 0}}</span>
          </li>
          <li>
            <span class="title">{{$t('totalAmount')}}</span>
            <span
              class="price"
            >₹ 0</span>
          </li>
          <li >
            <span class="title">Total Free VIP requests</span>
            <span
              class="price">2/month</span>
          </li>
          <li >
            <span class="title">Available Free VIP requests</span>
            <span
              class="price"
            >{{2 - maxLimitForVipUser<0?'0':2-maxLimitForVipUser}}</span>
          </li>


        </ul>
      </div>
    </div>
      <div class="request-info" v-if="popupData.rejection_msg">
      <div class="request-description">
         <div class="price-details-card">
        <h4>{{$t('rejectMessage')}}</h4>
         <p>{{popupData.rejection_msg}}</p>
         </div>

      </div>
      </div>
    <div class="request-btn" v-if="booked === 'VideoCreateInfo'" @click="sendRequest()">
      <span v-if="!isVipUser"> {{$t('requestVideo',{priceTxt:calculateRequestTotal(popupData.fee,discountAmount,userData.wallet)})}}</span>
      <span v-else>Request video for ₹ 0</span>
    </div>
        <div class="loader" v-if="loading">
      <img src="../../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import '../../assets/scss/booking-request.scss'
import constant from '../../utils/constant'
export default {
  name: 'VideoDetailInfo',
  props: {
    dataValue: {
      type: Object
    },
    user: {
      type: Object
    },
    createData: {
      type: Object
    }
  },
  data () {
    return {
      popupData: {},
      userData: {},
      userComment: '',
      commentData: {},
      booked: this.$route.name,
      discountAmount: 0,
      discountId: null,
      totalWalletAmount: 0,
      userWalletAmount: 0,
      loading: false,
      isVipUser:false,
      maxLimitForVipUser:0
    }
  },
  watch: {
    dataValue () {
      if (this.dataValue) {
        this.popupData = this.dataValue
      }
    },
    user () {
      if (this.user) {
        this.userData = this.user
        if (this.userData.discounts.length) {
          this.discountAmount = this.userData.discounts[0].discount_amount
        }
      }
    },
    createData () {
      if (this.createData) {
        this.commentData = this.createData
      }
    }
  },
  mounted () {

    if (this.dataValue) {
      this.popupData = this.dataValue
    }
    if (this.user) {
      this.userData = this.user
      if (this.userData.discounts.length) {
        this.discountAmount = this.userData.discounts[0].discount_amount
        this.discountId = this.userData.discounts[0].id
      }
    }
    if (this.createData) {
      this.commentData = this.createData
    }
    this.loading=true;
    this.$store.dispatch('checkUserVipStatus',this.popupData.id).then(response=>{
      console.log('response : ',response);
    // check if is a vip user and no of requests < 2
      if(this.booked === 'VideoCreateInfo'){
        if(response.data.user.is_vip && response.data.user.no_request < 2) {
          this.isVipUser = true;
          this.$set(this.popupData, 'fee', response.data.celebrity.disc_fee)
          this.maxLimitForVipUser=response.data.user.no_request
        }
      }
      else {
        if(response.data.user.is_vip && response.data.user.no_request <3 ) {
          this.isVipUser = true;
          this.$set(this.popupData, 'fee', response.data.celebrity.disc_fee)
          this.maxLimitForVipUser=response.data.user.no_request
        }
      }
      this.loading=false;
    })
  },
  methods: {
    calculateTotal (wallet, discount, gateway) {
      return (discount + wallet + gateway).toFixed(2)
    },
    calculateRequestTotal (fee, discount, wallet) {
      let feeAmount
      let discountAmount
      let walletAmount

      if (!fee) {
        feeAmount = 0
      } else {
        feeAmount = fee
      }
      if (!discount) {
        discountAmount = 0
      } else {
        discountAmount = discount
      }
      if (!wallet) {
        walletAmount = 0
      } else {
        walletAmount = wallet
      }
      let totalAmount = feeAmount - discountAmount
      if (walletAmount > totalAmount) {
        this.totalWalletAmount = 0
        return 0
      } else {
        this.totalWalletAmount = (totalAmount - walletAmount).toFixed(2)
        return (totalAmount - walletAmount).toFixed(2)
      }
    },
    celebsCalulate (Discount, wallet, gatewayAmount) {
      return Discount + wallet + gatewayAmount
    },
    calculateDisCount (celeAmount, discountAmount, wallet) {
      let amount = (celeAmount - discountAmount).toFixed(2)
      if (wallet > amount) {
        this.userWalletAmount = amount
        return amount
      } else {
        this.userWalletAmount = wallet
        return wallet.toFixed(2)
      }
    },
    calculateDisAmount (discount, fee) {
      if (discount > fee) {
        this.discountAmount = fee
        return fee.toFixed(2)
      } else {
        return discount.toFixed(2)
      }
    },
    popup () {
      if (this.$route.name === 'VideoCreateInfo') {
        this.$emit('celebsPopupStatus', true)
      } else if (this.$route.name === 'Profile') {
        this.$emit('popupStatus', true)
      }
    },
    ChangeColor (status) {
      if (status === 'Reject') {
        return this.$t('reject')
      } else if (status === 'Pending') {
        return this.$t('pending')
      } else if (status === 'Accept') {
        return this.$t('approved')
      } else if (status === 'Complete') {
        return this.$t('video')
      } else if (status === 'Incomplete') {
        return this.$t('incomplete')
      }
    },
    ChangeStatus (status) {
      if (status === 'Reject') {
        return 'reject'
      } else if (status === 'Pending') {
        return 'pending'
      } else if (status === 'Accept') {
        return 'approved'
      } else if (status === 'Complete') {
        return 'video'
      } else if (status === 'Incomplete') {
        return 'incomplete'
      }
    },
    sendRequest () {
      this.loading = true
      if(this.isVipUser)
        this.popupData.fee = 0;
      this.$store
        .dispatch({
          type: 'requestedVideo',
          payload: {
            request_message: this.commentData.message,
            mode: this.commentData.status,
            celebrity_id: this.popupData.id,
            wallet: this.popupData.fee,
            discount_id: this.discountId,
            name: this.commentData.name
          }
        })
        .then(data => {
          if (data.data.status === 'request failed') {
            // this.$router.push({ name: 'Success' })
            let access = (data.data.wallet_diff).toFixed(2)
            if (access == Number(this.totalWalletAmount)) {
              this.loading = true
              var _ = `${constant.BASE_PAYMENT_URL}/payment/?gateway_amount=${
                data.data.wallet_diff
              }&user_id=${this.userData.id}&celeb_id=${
                this.popupData.id
              }&mpoints_amount=0&wallet_amount=${
                this.userWalletAmount
              }&request_message=${
                this.commentData.message
              }&mode=${this.commentData.status}&name=${
                this.commentData.name}`
              window.location = _
            }
          } else {
            this.$router.push({ name: 'Success' })
          }
        })
    }
  }
}
</script>

<style>
</style>
