<template>
    <div class="payment-successfull">
    <div class="success-content">
      <span class="success-img">
        <img src="../../assets/images/payment-success.svg" alt="">
      </span>
      <h1>{{$t('congratulation')}}</h1>
      <p>{{$t('congratulationMessage')}} </p>
    </div>
  </div>
</template>

<script>
import '../../assets/scss/booking-request.scss'
import { mapMutations } from 'vuex'
export default {
  name: 'Success',
  data () {
    return {

    }
  },
  mounted () {
    setTimeout(() => {
      this.orderIndex()
      this.$router.push({ name: 'Profile' })
    }, 2000)
  },
  methods: {
    ...mapMutations(['saveProfileOrdersList']),
    orderIndex () {
      console.log('fgbnjgnbkngkbnkjgnb')
      this.saveProfileOrdersList({
        profileOrdersList: [],
        profileOrdersListIndex: [],
        userProfileData: {}
      })
    }
  }
}
</script>

<style>

</style>
