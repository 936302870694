<template>
  <div class="celebrities-gallery">
    <div class="celebrity-photo">
      <span v-for="(list,index) in instagramData" :key="index" @click="i = index">
        <img :src="list.standard_res" alt />
      </span>
    </div>
    <gallery :images="img" id="scnd" :index="i" @close="i = null"></gallery>
     <Toaster :dataValue="toasterObj" />
    <infinite-loading spinner="waveDots" @infinite="getPhoto">
          <div slot="no-more">......</div>
      <div class="no-data-status" slot="no-results">
        <span class="no-dt">{{$t('noPhoto')}} </span>
      </div>
    </infinite-loading>
    <div class="loader" v-if="loading">
      <img src="../../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import VueGallery from 'vue-gallery'
import { mapGetters, mapMutations } from 'vuex'
import Toaster from '../Common/Toaster'
export default {
  name: 'CelebrityPhoto',
  components: {
    gallery: VueGallery,
    Toaster
  },
  props: {
  },
  data () {
    return {
      instagramData: [],
      photoStartIndex: 0,
      img: [],
      i: null,
      loading: false,
      toasterObj: {}
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      'celebrityPhotos',
      'celebrityPhotosIndex',
      'celebrityDetail'
    ])
  },
  mounted () {


    if (this.celebrityPhotos) {
      this.instagramData = this.celebrityPhotos
      this.photoStartIndex = this.celebrityPhotosIndex
      this.popupImage(this.instagramData)
    }
  },
  methods: {
    ...mapMutations(['saveCelebrityPhotos']),
    getPhoto ($state) {
      this.loading = true
      this.$store
        .dispatch({
          type: 'getCelebrityPhoto',
          startIndex: this.photoStartIndex,
          id: this.$route.params.id
        })
        .then(data => {
          if (data.data.length > 0) {
            this.instagramData.push(...data.data)
            this.photoStartIndex = this.photoStartIndex + 20
            this.popupImage(this.instagramData)
            this.saveCelebrityPhotos({
              celebrityPhotos: this.instagramData,
              celebrityPhotosIndex: this.photoStartIndex
            })
            $state && $state.loaded()
          } else {
            $state && $state.complete()
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    },
    popupImage (data) {
      this.img = []
      for (let i = 0; i <= data.length; i++) {
        if (data[i] && data[i].mimetype === 'Image') {
          this.img.push(data[i].standard_res)
        }
      }
    }
  }
}
</script>

<style>
</style>
