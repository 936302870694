<template>
  <div>
    <div class="request-block">
      <header class="request-header">
        <span class="goback-btn">
          <img @click="back()" src="../../assets/images/goback-arrow.svg" alt />
        </span>
        <div class="head-profile">
          <span class="user-pf-img">
            <img :src="celebrityData.profile_pic" alt />
          </span>
          <span class="user-title">{{celebrityData.name}}</span>
        </div>
      </header>

      <div class="request-info">
        <form>
          <h4>{{$t('createVideoDetail')}}</h4>
          <label class="radio-container">
            {{$t('createVideoDetailInfo')}}
            <input
              type="radio"
              name="radio"
              value="self"
              v-model="createVideoData.status"
              @change="changeStatus('self')"
            />
            <span class="checkmark"></span>
          </label>
          <label class="radio-container">
            {{$t('createVideoDetailInfo1')}}
            <input
              type="radio"
              name="radio"
              value="other"
              v-model="createVideoData.status"
              @change="changeStatus('other')"
            />
            <span class="checkmark"></span>
          </label>
          <h4 class="text-space" v-if="createVideoData.status !=='self'">{{$t('createVideoDetailInfo2')}}</h4>
          <h4 class="text-space" v-if="createVideoData.status ==='self'">{{$t('createVideoDetailInfo7')}}</h4>
          <input
            type="text"
            class="input-field"
            v-model="createVideoData.name"
            :placeholder="$t('createVideoDetailInfo3')"
          />
          <h4
            class="text-space"
          >{{$t('createVideoDetailInfo4',{name:celebrityData.name})}}</h4>
          <textarea
            name
            rows="7"
            id
            v-model="createVideoData.message"
            :placeholder="$t('createVideoDetailInfo5')"
          ></textarea>
          <span class="note">{{$t('createVideoDetailInfo6')}}</span>
        </form>
      </div>
      <Toaster :dataValue="toasterObj" />
      <div class="request-btn">
        <span @click="requestVideo()">{{$t('requestVideo',{priceTxt:celebrityData.fee})}}</span>
      </div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animated bounceInRight">
      <VideoDetailInfo
        v-if="!finalVideoStatus"
        :user="userData"
        :createData="createVideoData"
        :dataValue="celebrityData"
        @celebsPopupStatus="checkfinalStatus"
      />
    </transition>
  </div>
</template>

<script>
import VideoDetailInfo from '../Common/VideoDetailInfo'
import Toaster from '../Common/Toaster'

export default {
  name: 'VideoCreate',
  components: {
    VideoDetailInfo,
    Toaster
  },
  props: {
    celebrity: {
      type: Object
    },
    user: {
      type: Object
    }
  },
  data () {
    return {
      createVideoData: {
        status: 'self'
      },
      celebrityData: {},
      finalVideoStatus: true,
      userData: {},
      toasterObj: {}
    }
  },
  watch: {
    celebrity () {
      if (this.celebrity) {
        this.celebrityData = this.celebrity
      }
    },
    user () {
      if (this.user) {
        this.userData = this.user
      }
    }
  },
  mounted () {
    if (this.celebrity) {
      this.celebrityData = this.celebrity
    }
    if (this.user) {
      this.userData = this.user
    }
  },
  methods: {
    changeStatus (status) {
      // this.$cookies.set("status", order_status);
      this.createVideoData.status = status
    },
    requestVideo () {
      if (this.validateFields()) {
        this.finalVideoStatus = false
      }
    },
    validateFields () {
      var form = this.createVideoData
      if (!form.name || !form.message) {
        this.toasterObj = {
          status: true,
          msg: this.$t('validationError'),
          color: '#000000'
        }
        return false
      } else {
        if (form.message.length <= 9) {
          this.toasterObj = {
            status: true,
            msg: this.$t('validationErrorChar10'),
            color: '#000000'
          }
          return false
        }
        if (form.name.length <= 2) {
          this.toasterObj = {
            status: true,
            msg: this.$t('validationErrorChar3'),
            color: '#000000'
          }
          return false
        }
        return true
      }
    },
    back () {
      this.$emit('videoCreate', true)
    },
    checkfinalStatus (value) {
      this.finalVideoStatus = value
    }
  }
}
</script>

<style>
</style>
