<template>
  <div class="celebrity-info">
    <div class="bg-block">
      <!-- <img
        :src="celebrityInfo.wall"
        alt
        class="celebty-bg-img"
      /> -->
      <ImageItem
        class="article-item__image"
        v-if="celebrityInfo.wall"
        :source="celebrityInfo.wall" 
      />
      <div class="celebrity-profile">
        <span class="prof-img">
          <img :src="celebrityInfo.profile_pic" alt />
        </span>
        <div class="prof-content">
          <h3>{{celebrityInfo.name}}</h3>
          <p>{{celebrityInfo.profession}}</p>
          <span>Rs.{{celebrityInfo.fee}}</span>
          <strike>Rs. 500</strike>
        </div>
      </div>
    </div>
    <div class="about-celebrity">
      <h2>{{$t('aboutMe')}}</h2>
      <p>{{celebrityInfo.about}}</p>
    </div>
  </div>
</template>

<script>
import ImageItem from '../Common/ImageItem'
export default {
  name: 'CelebrityInfo',
  components: {
    ImageItem
  },
  props: {
    dataValue: {
      type: Object
    }
  },
  data () {
    return {
      celebrityInfo: {}
    }
  },
  watch: {
    dataValue () {
      if (this.dataValue) {
        this.celebrityInfo = this.dataValue
      }
    }
  },

  mounted () {
    if (this.dataValue) {
      this.celebrityInfo = this.dataValue
    }
  },
  methods: {}
}
</script>
