<template>
  <div>
      <div class="request-block">
        <header class="request-header">
          <span class="goback-btn">
            <img @click="back()" src="../../assets/images/goback-arrow.svg" alt />
          </span>
          <div class="head-profile">
            <span class="user-pf-img">
              <img :src="celebrityData.profile_pic" alt />
            </span>
            <span class="user-title">{{celebrityData.name}}</span>
          </div>
        </header>
        <div class="request-info">
          <h2>{{$t('Instructions')}}</h2>
          <ul class="info-points">
            <li>{{$t('requestDetailInfo1')}}</li>
            <li>{{$t('requestDetailInfo2')}}</li>
          </ul>
        </div>
        <div class="request-info">
          <h2>{{$t('requestDetailInfo3')}}</h2>
          <ul class="info-points">
            <li>{{$t('requestDetailInfo4')}}</li>
            <li>{{$t('requestDetailInfo5')}}</li>
            <li>{{$t('requestDetailInfo6')}}</li>
          </ul>
        </div>
        <Toaster :dataValue="toasterObj" />
        <div class="request-btn">
          <span @click="fillData()">{{$t('requestVideo',{priceTxt:celebrityData.fee})}}</span>
        </div>
      </div>

    <transition name="custom-classes-transition" enter-active-class="animated bounceInRight">
      <VideoCreate
        :celebrity="celebrityData"
        :user="userData"
        v-if="!fillDataStatus"
        @videoCreate="changeFillStatus"
      />
    </transition>
    <div class="loader" v-if="loading">
      <img src="../../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import '../../assets/scss/booking-request.scss'
import VideoCreate from '../CreateVideo/VideoCreate'
import { mapGetters, mapMutations } from 'vuex'
import Toaster from '../Common/Toaster'
export default {
  name: 'VideoCreateInfo',
  components: {
    VideoCreate,
    Toaster
  },
  data () {
    return {
      celebrityData: {},
      userData: {},
      fillDataStatus: true,
      loading: false,
      toasterObj: {}
    }
  },
  computed: {
    ...mapGetters(['userProfileData', 'celebrityDetail'])
  },
  mounted () {
    if (this.userProfileData.length && this.celebrityDetail.length) {
      this.userData = this.userProfileData
      this.celebrityData = this.celebrityDetail
    } else {
      this.getCelebrityDetail()
    }
  },
  methods: {
    ...mapMutations(['saveCelebrityDetail', 'saveUserProfileData']),
    back () {
      this.$router.go(-1)
    },
    fillData () {
      this.fillDataStatus = false
    },
    changeFillStatus (value) {
      this.fillDataStatus = value
    },
    getCelebrityDetail () {
      this.loading = true
      this.$store
        .dispatch({
          type: 'getCelebrityDetail',
          id: this.$route.params.id
        })
        .then(data => {
          if (!this.userProfileData.length) {
            this.userData = data.data.user
            this.saveUserProfileData({ userProfileData: data.data.user })
          }
          this.celebrityData = data.data.celebrity
          this.saveCelebrityDetail({ celebrityDetail: data.data.celebrity })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    }
  }
}
</script>
